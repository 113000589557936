/**
 * Header fixed on scroll
 */
(function () {
    var top;
    var header = $('#main-header');
    $(window).scroll(function () {
        top = $(window).scrollTop();
        if (top > (header.offset().top)) {
            header.addClass('header-fixed');
            if ($('#search-desktop').hasClass('searching')) {
                $('#main-header').addClass('searching');
            }
        }
        if ( ($("#div-gpt-top-leaderboard").length) && !($("#div-gpt-top-leaderboard").css('display') === 'none') ) {
            if (top < ($('#div-gpt-top-leaderboard').offset().top - header.height()) || top === 0) {
                header.removeClass('header-fixed');
                $('#div-gpt-top-leaderboard').css({'margin-top':0});
                return;
            }
        } else {
            if (top < ($('.content-main-wrapper').offset().top - header.height()) || top === 0) {
                header.removeClass('header-fixed');
                $('.content-main-wrapper').css({'margin-top':0});
            }
        }
    });
})();

/**
 * Search box
 */
(function () {
    $('#search-desktop').click(function (e) {
        e.preventDefault();
        $('.search-form-desktop-container').removeClass('hidden');
        $('#search-desktop').addClass('searching');
        $("#search-desktop-value").focus();
        $(document).keydown(function(e) {
            if (e.keyCode == 27) {
                $('.search-form-desktop-container').addClass('hidden');
                $('#search-desktop').removeClass('searching');
                $('#main-header').removeClass('searching');
                return;
            }
        });
        $('#search-desktop-action').click(function (e) {
            e.preventDefault();
            if ($('#search-desktop-value').val() == '') {
                $('.search-form-desktop-container').addClass('hidden');
                $('#search-desktop').removeClass('searching');
                $('#main-header').removeClass('searching');
                return;
            }  else {
                $('#desktop-form').submit();
            };
        });
    });

    $('#search-mobile').click(function () {
        $('.search-form-container').removeClass('hidden');
        $('#search-mobile').addClass('hidden');
        $("#search-value").focus();
        $('#search-action').click(function (e) {
            e.preventDefault();
            if ($('#search-value').val() == '') {
                $('.search-form-container').addClass('hidden');
                $('#search-mobile').removeClass('hidden');
            }  else {
                $('#mobile-form').submit();
            };
        });
    });

    $('#search-river-action').click(function (e) {
        e.preventDefault();
        if ($('#search-river-value').val() != '') {
            $('#river-form').submit();
        };
    });
})();

/**
 * Collapse Events
 */
(function () {
    $('.collapse').on('shown.bs.collapse', function () {
        $(this).parent().find('a[href$=#'+$(this).prop('id')+'] > i').toggleClass('minus-sign');
    });
    $('.collapse').on('hidden.bs.collapse', function () {
        $(this).parent().find('a[href$=#'+$(this).prop('id')+'] > i').toggleClass('minus-sign');
    });
})();

/**
 * Subscription
 */
(function () {
    $("#subs-digital").on('change', function () {
        if ($(this).is(':checked')) {
            $('.magazine-img, .ipad-img').toggleClass('hidden');
            $('#subs-gift, #delivery-location, #subs-regular, #discount').parent().toggleClass('hidden');
            $('.g-recaptcha').css('display', 'inline-block');
            $('.btn-subscribe, .print-subscribe-form').toggleClass('hidden');
        }
    });
    $("#subs-print").on('change', function () {
        if ($(this).is(':checked')) {
            if ($('.magazine-img').hasClass('hidden')) {
                $('.magazine-img').toggleClass('hidden');
            }
            if ($('#subs-gift, #delivery-location, #subs-regular, #discount, .btn-subscribe').parent().hasClass('hidden')) {
                $('.btn-subscribe, .print-subscribe-form').toggleClass('hidden');
                $('.g-recaptcha').css('display', 'none');
                $('#subs-gift, #delivery-location, #subs-regular, #discount').parent().toggleClass('hidden');
            }
            if (!$('.ipad-img').hasClass('hidden')) {
                $('.ipad-img').toggleClass('hidden');
            }
            if ($('#gift-form').hasClass('hidden')) {
                $("#subs-regular").prop("checked", true);
            } else {
                $("#subs-gift").prop("checked", true);
            }
        }
    });
    $("#subs-gift").on('change', function () {
        if ($(this).is(':checked')) {
            $('#gift-form').toggleClass('hidden');
            $('#regular-form').toggleClass('hidden');
        }
    });

    $("#subs-regular").on('change', function () {
        if ($(this).is(':checked')) {
            $('#gift-form').toggleClass('hidden');
            $('#regular-form').toggleClass('hidden');
        }
    });

    $('.subscribe-form').submit(function (e){
        e.preventDefault();
        window.location.href = "http://www.barnesandnoble.com/w/house-and-home-256media/1119575169?ean=2940149125326";
    });
})();

/**
 * Cookie notice
 */
(function () {
    // Cookie warning notice - if cookie does not exist, show it and set 6 month cookie
    if (!$.cookie('cnotice')) {
        $('#cookie-notice').show();
        // Click handler for X in cookie notice. Handler not needed unless it's being shown.
        $('#cookie-close').click(function() {
            $('#cookie-notice').hide();
        });
        $.cookie('cnotice', 1, { expires: 180, path: '/' });
    }
})();


/**
 * Analytics event hooks
 */
(function () {
    // Send events for analytics-tagged events
    $(document).on('click', '.analytics', function(){
        ga(
            'send',
            'event',
            $(this).data('category'),
            $(this).data('label'),
            $(this).data('value')
        );
    });
})();

(function(){
    // Infinite Scroll
    //pseudo-namespace
    var HOUSEANDHOME_NS = {};

    HOUSEANDHOME_NS.infinitescroll = {
        currentPage: 1,
        numPages: undefined,
        debug: false,
        verbose: false,
        firstFallback: false,
        finalFallback: false,
        nextRoute: undefined,
        selector: '.river-scroll-wrapper',
        container: '.infinite-scroll',
        bigSize: false,
        init: function () {
            HOUSEANDHOME_NS.infinitescroll.nextRoute = $(HOUSEANDHOME_NS.infinitescroll.container).data('firstroute')+'page/',
            HOUSEANDHOME_NS.infinitescroll.numPages = $(HOUSEANDHOME_NS.infinitescroll.container).data('pages');
            // Search river case
            var query = '';
            var searchTerm = $('#next-page-link').data('query');
            if (searchTerm) {
                query = ('?q='+searchTerm).replace(/\s/g, '+');
            }
            if ($('.infinite-scroll')) {
                $(HOUSEANDHOME_NS.infinitescroll.selector).infinitescroll({
                    debug        : HOUSEANDHOME_NS.infinitescroll.debug,
                    navSelector  : "div.nav-selector",
                    nextSelector : "a.next",
                    itemSelector : ".posts-wrapper",
                    loading: {
                        finished: undefined,
                        finishedMsg: "",
                        img: '/i/loading.gif',
                        msg: null,
                        msgText: "",
                        selector: 'div.loading-icon',
                        speed: 'fast',
                        start: undefined
                    },
                    state: {
                        isDuringAjax: false,
                        isInvalidPage: false,
                        isDestroyed: false,
                        isDone: false,
                        isPaused: false,
                        currPage: 1
                    },
                    behavior: undefined,
                    binder: $(window),
                    contentSelector: HOUSEANDHOME_NS.infinitescroll.selector,
                    extraScrollPx: 150,
                    animate: false,
                    pathParse: undefined,
                    dataType: 'html',
                    appendCallback: true,
                    bufferPx: 40,
                    errorCallback: function (e) {
                        // If we're done, unset the container
                        $('#pagination-widget').remove();
                    },
                    infid: 0,
                    pixelsFromNavToBottom: undefined,
                    path: [HOUSEANDHOME_NS.infinitescroll.nextRoute, '/' + query],
                    maxPage: 25
                }, function(data) {
                    /* HEADS UP!!
                     * Check if is last results page, if so, then remove the "pagination widget" object to avoid empty loads.
                     */
                    if ($('.infinite-scroll').data('pages') == HOUSEANDHOME_NS.infinitescroll.currentPage) {
                        // If data is empty, remove pagination widget,no fallBack results here.
                        $(HOUSEANDHOME_NS.infinitescroll.container).remove();
                    }
                    HOUSEANDHOME_NS.infinitescroll.currentPage++;

                    //update the link target after load
                    $("#next-page-link").attr(
                        'href',
                        HOUSEANDHOME_NS.infinitescroll.nextRoute+HOUSEANDHOME_NS.infinitescroll.currentPage
                    );

                    displayAds();

                });
            }
        }
    };
    /**
     * Inifinte scrolling
     */
    HOUSEANDHOME_NS.infinitescroll.init();

    /**
    * Display ads on river
    */
    function displayAds() {
        var divs = [];
        googletag.cmd.push(function () {
            var mappingLeaderboard = googletag.sizeMapping()
                .addSize([968, 450], [970, 250])
                .addSize([0, 0], [300, 250])
                .build();
            var mappingMPU = googletag.sizeMapping()
                .addSize([769, 450], [300, 250])
                .addSize([0, 0], [300, 250])
                .build();
            $('.river-ad').each(function() {
                $(this).removeClass('river-ad');
                // Targeting data all in a data- elements
                var data = JSON.parse(JSON.stringify($(this).data('targeting')));
                var divId = $(this).attr('id');
                var type = $(this).data('type');
                divs.push(divId);
                if ( type == 'leaderboard') {
                    var slot = googletag.defineSlot('/91747435/houseandhome_river_leaderboard', [1, 1], divId)
                        .setTargeting(data)
                        .defineSizeMapping(mappingLeaderboard)
                        .addService(googletag.pubads());
                } else {
                    var slot = googletag.defineSlot('/91747435/houseandhome_river_mpu', [1, 1], divId)
                        .setTargeting(data)
                        .defineSizeMapping(mappingMPU)
                        .addService(googletag.pubads());
                }
            });
            googletag.pubads().enableSingleRequest();
            googletag.enableServices();
        });
        googletag.cmd.push(function () {
            divs.forEach(function(val){
                googletag.display(val);
            });
        });
    };

})();

/* Pinterest is a mess. The hover js will take any link with /create/button and turn it in
to a pinterest-style button. This messes up our fontawesome icons, so we have links without
a href, subbing it in here from another data-element */
(function () {
    $(document).on('click', '.pinterest-holder', function() {
        $(this).attr("target","_blank");
        $(this).prop('href', $(this).data('holder'));
        $(this).removeClass('pinterest-holder');
    });
})();

/**
 * Video page, swap in video
 */
(function () {
    $('.video-overlay').click(function() {
        // Need to hack in autoplay=1 on the end of the url
        // Embed may or may not already have a query string attached
        var src = $('#embedded-video-player').attr('src');
        if (src.indexOf('?') != -1) {
            src += '&';
        } else {
            src += '?';
        }
        src += 'autoplay=1';
        $('#embedded-video-player').attr('src', src);
        // Swap out the picture with the youtube video, now playing
        $('.main-image-container').replaceWith($('.video-container').removeClass('hidden'));
    });
})();

/**
 * Badges Modal
 */
(function () {
    var href = '<a rel="external" href="http://www.houseandhome.ie/?utm_source=vendors&amp;utm_medium=badge&amp;utm_campaign=house_and_home_badges" target="_blank">'
    $('.badge-code').click(function () {
        $('.modal-title').text('HouseAndHome badge size: ' + $(this).attr('data-size'));
        $('#code').text(href + "<img src='" + $(this).attr('data-ref') + "'></a>");
        $('#badge-modal').modal();
    });
})();

/**
 * Mailing list subscription
 */
(function () {
    // Mailing list signup form
    // Intercept post, turn it to ajax
    $('#modal-form').submit(function(e) {
        e.preventDefault();

        if ($(this).find('input[type=email]').val().length > 0) {
            // Change form display while sending
            $('#modal-form-message').removeClass('hidden');
            $(this).hide();
            // Build ajax
            $.post(
                '/list-sub',
                { email: $(this).find('input[type=email]').val() }
            ).done(function(data) {
                // And again upon return
                $('#modal-form-message').html('<h2>'+data.message+'</h2>');
            });
            window.setTimeout(function (){
                $('#subscription-modal').modal('hide');
            }, 6000);
        }
    });

    // Mailing list signup form
    // Intercept post, turn it to ajax
    $('#footer-form').submit(function(e) {
        e.preventDefault();

        if ($(this).find('input[type=email]').val().length > 0) {
            // Change form display while sending
            $('#footer-form-message').removeClass('hidden');
            $(this).hide();
            // Build ajax
            $.post(
                '/list-sub',
                { email: $(this).find('input[type=email]').val() }
            ).done(function(data) {
                // And again upon return
                $('#footer-form-message').html('<h2>'+data.message+'</h2>');
            });
        }
    });

    // Mailing list for sidebar form
    $('#form-sidebar').submit(function(e) {
        e.preventDefault();

        if ($(this).find('input[type=email]').val().length > 0) {
            // Change form display while sending
            $('.for-sidebar #footer-form-message').removeClass('hidden');
            $(this).hide();
            // Build ajax
            $.post(
                '/list-sub',
                { email: $(this).find('input[type=email]').val() }
            ).done(function(data) {
                // And again upon return
                $('.for-sidebar #footer-form-message').html('<h2>'+data.message+'</h2>');
            });
        }
    });

    // Navbar Desktop
    $('.nav-item').hover(function () {
        $(this).children('.nav-sublist').toggle();
    })

    // Navbar Mobile
    $('.nav-item-m').click(function () {
        $(this).children('.nav-sublist').toggle();
    })

})();

/**
 * Datepicker
 */
const initFlatpickr = (selector) => {
    $(selector).flatpickr({
        altInput: true,
        plugins: [
            new monthSelectPlugin({
                shorthand: false, //defaults to false
                dateFormat: "Y-m", //defaults to "F Y"
                altFormat: "F Y", //defaults to "F Y"
                theme: "light" // defaults to "light"
            })
        ]
    });
}
(function () {
    $('input.datepicker').each(function () {
        initFlatpickr(this);
    });
})();
